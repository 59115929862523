<template>
  <div class="mt-16">
    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span>Select an Organization</span>
      </h2>
    </div>
    <div class="d-flex justify-center mt-8">
      <div class="d-flex justify-space-around mt-4 flex-wrap" style="width: 75%">
        <template v-for="org in orgs">
          <v-hover v-slot="{ hover }" :key="org.id">
            <v-card class="ml-2 mr-2 mt-4" :class="{ 'on-hover': hover }" @click="selectOrg(org.id)">
              <v-card-title class="justify-center">{{ org.name }}</v-card-title>
              <v-img :src="org.logoURL ? org.logoURL : defaultImage" max-width="200"></v-img>
            </v-card>
          </v-hover>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import firebase from 'firebase'

export default {
  data() {
    return {
      icons: {
        mdiAlert,
      },
      defaultImage: require('../assets/images/avatars/1.png'),
      jdc: require('../assets/images/svg/logo.png'),
      orgs: [],
    }
  },
  methods: {
    selectOrg(id) {
      // let data = this.$store.state.userData
      // data.selectedOrg = id
      // this.$store.commit('setUserData', data)
      sessionStorage.setItem('selectedOrg', id)

      this.$router.push({ path: '/dashboard' })
    },
    getOrgsData: async function () {
      try {
        let promises = []
        let userData = JSON.parse(sessionStorage.userData)
        userData.orgs.forEach(org => {
          promises.push(
            firebase
              .firestore()
              .collection('orgs')
              .doc(org)
              .get()
              .then(doc => {
                if (doc.exists) {
                  let data = doc.data()
                  data.id = doc.id
                  this.orgs.push(data)
                }
              }),
          )
        })

        await Promise.all(promises)
      } catch (error) {
        console.log(`Error getting organization data: ${error}`)
      }
    },
  },
  mounted() {
    this.getOrgsData()
  },
}
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
  border: 2px solid white;
  cursor: pointer;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
